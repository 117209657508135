import React from 'react';

const SprinterSell = () => {
    return (
        <div className='hero--section'>
          <button>go back</button>
            <h1>SPRINTER SELL</h1>
        </div>
    );
}

export default SprinterSell;
