import React from 'react';

const CreeperCorners = () => {
    return (
        <div className='hero--section'>
          <button>go back</button>
            <h1>CREEPER CORNERS</h1>
        </div>
    );
}

export default CreeperCorners;
